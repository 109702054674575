import signupFlow from '@/utils/signupFlow'

const state = {
  businessLogin: '',
  companyName: '',
  userId: '',
  firstName: '',
  lastName: '',
  photoURL: '',
  email: '',
  password: '',
  industry: '',
  subIndustry: '',
  phoneNumber: '',
  referral: '',
  source: null,
  termsAccepted: false,
  isAppSumo: false,
  appSumoLicenseUuid: '',
  timezone: 'Europe/London',
  referrer: {},
  theme: '',
  impactClickId: '',
  impactPartnerId: '',
  selectedPlan: null,
  selectedPlanId: null,
  signupFlow: signupFlow.FREE_TRIAL_WITHOUT_CARD,
  currencySymbol: '$',
  paddleCheckoutId: null,
  gtagDataLayerOption: 'B', // option A Trial with card // Option B Try for Free // Option C direct link to signup and choosing plan
  shoplazzaStoreName: '',
  pricesFromPaddle: {
    subtotal: 0,
    taxes: 0,
    total: 0,
    recurringPrice: 0
  },
  language: 'en'
}

const getters = {}

const actions = {}

const mutations = {
  changeBusinessAccount (state, payload) {
    state.businessLogin = payload.value
  },

  changeCompanyName (state, payload) {
    state.companyName = payload.value
  },

  changeFirstName (state, payload) {
    state.firstName = payload.value
  },

  changeLastName (state, payload) {
    state.lastName = payload.value
  },

  changeEmail (state, payload) {
    state.email = payload.value
  },

  changePassword (state, payload) {
    state.password = payload.value
  },

  changeIndustry (state, payload) {
    state.industry = payload
  },

  changeSubIndustry (state, payload) {
    state.subIndustry = payload.value
  },

  changePhoneNumber (state, payload) {
    state.phoneNumber = payload.value
  },

  changeReferral (state, payload) {
    state.referral = payload.value
  },

  changeTheme (state, payload) {
    state.theme = payload
  },

  changeTermsAccepted (state, payload) {
    state.termsAccepted = payload.value
  },

  changeUserId (state, value) {
    state.userId = value
  },

  setIsAppSumo (state, payload) {
    state.isAppSumo = payload
  },

  setSource (state, payload) {
    state.source = payload
  },

  setAppSumoLicenseUuid (state, payload) {
    state.appSumoLicenseUuid = payload
  },

  changeTimezone (state, payload) {
    state.timezone = payload
  },

  setReferrerValue (state, payload) {
    state.referrer = payload
  },

  setImpactClickId (state, payload) {
    state.impactClickId = payload
  },

  setImpactPartnerId (state, payload) {
    state.impactPartnerId = payload
  },

  setSocialData (state, payload) {
    state.userId = payload.id
    state.photoURL = payload.photoURL
    state.firstName = payload.firstName
    state.lastName = payload.lastName
    state.email = payload.email
  },

  setSelectedPlan (state, payload) {
    state.selectedPlan = payload
  },

  setCurrencySymbol (state, payload) {
    state.currencySymbol = payload
  },

  setSignupFlow (state, payload) {
    state.signupFlow = payload
  },

  setPaddleCheckoutId (state, payload) {
    state.paddleCheckoutId = payload
  },

  setSelectedPlanId (state, payload) {
    state.selectedPlanId = payload
  },

  setGtagDataLayerOption (state, payload) {
    state.gtagDataLayerOption = payload
  },

  setPricesFromPaddle (state, payload) {
    state.pricesFromPaddle = payload
  },

  setLanguage (state, payload) {
    state.language = payload
  },

  setShoplazzaStoreName (state, payload) {
    state.shoplazzaStoreName = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
