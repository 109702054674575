import { createApp } from 'vue'
import App from './App.vue'
import { router } from './router'
import * as Sentry from '@sentry/vue'
import store from './store'
import axios from 'axios'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import VueAuthenticate from 'vue-authenticate'
import { SocialAuthOptions } from '@/options/socialAuthOptions'
import './registerServiceWorker'
import i18n from './i18n'
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';

axios.defaults.baseURL = import.meta.env.VITE_BACKOFFICE_URL

const app = createApp(App)

app.config.globalProperties.$http = axios

const VueAuthenticateInstance = VueAuthenticate.factory(app.config.globalProperties.$http, SocialAuthOptions)
app.config.globalProperties.$auth = VueAuthenticateInstance

const routerInstance = router(i18n)

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    app,
    environment: import.meta.env.VITE_SENTRY_ENV,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration({routerInstance}), Sentry.replayIntegration()],
    // // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  })
}

app.use(ElementPlus)
app.use(routerInstance)
app.use(store)
app.use(i18n)
app.use(VueTelInput);
app.mount('#app')
