import { createI18n } from 'vue-i18n'
import en from '@/lang/en.json'
import sr from '@/lang/sr-Latn.json'
import da from '@/lang/da.json'
import de from '@/lang/de.json'
import nl from '@/lang/nl.json'
import sv from '@/lang/sv.json'

export default createI18n({
  locale: import.meta.env.VUE_DEFAULT_LOCALE,
  fallbackLocale: import.meta.env.VUE_DEFAULT_LOCALE,
  legacy: false,
  // Key - language to use the rule for, `'sr-Latn'`, in this case
  // Value - function to choose right plural form
  pluralizationRules: {
    /**
     * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
     * @param choices {number} an overall amount of available choices
     * @returns a final choice index to select plural word by
     */
    'sr-Latn': (choice, choices) => {
      // this === VueI18n instance, so the locale property also exists here

      if (choice === 0) {
        return 0
      }

      const teen = choice > 10 && choice < 20
      const endsWithOne = choice % 10 === 1

      if (choices < 4) {
        return (!teen && endsWithOne) ? 1 : 2
      }
      if (!teen && endsWithOne) {
        return 1
      }
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2
      }

      return (choices < 4) ? 2 : 3
    }
  },

  messages: {
    en,
    sr,
    da,
    de,
    nl,
    sv
  }
})
