import { createRouter as _createRouter } from 'vue-i18n-routing'
import { createWebHistory } from 'vue-router'

const router = function (i18n) {
  return _createRouter(i18n, {
    version: 4,
    defaultLocale: 'en',
    baseUrl: '',
    locales: [
      {
        code: 'en',
        name: 'English'
      },
      {
        code: 'sr',
        name: 'Serbian'
      },
      {
        code: 'da',
        name: 'Danish'
      },
      {
        code: 'nl',
        name: 'Dutch'
      },
      {
        code: 'de',
        name: 'German'
      },
      {
        code: 'sv',
        name: 'Swedish'
      }
    ],
    history: createWebHistory(),
    routes: [
      {
        path: '/',
        name: 'sign-up',
        meta: {
          title: 'Sign up'
        },
        component: () => import('./views/Signup.vue'),
        children: [
          {
            meta: {
              title: 'Account',
              isSignupStep: true,
              step: 1
            },
            component: () => import('./views/SignupSteps/Account/AccountSignup.vue'),
            path: '',
            name: 'account'
          },
          {
            meta: {
              title: 'Business account',
              isSignupStep: true,
              step: 2
            },
            component: () => import('./views/SignupSteps/BusinessAccount.vue'),
            path: 'business-account',
            name: 'business-account'
          },
          {
            meta: {
              title: 'Theme',
              isSignupStep: true,
              step: 3
            },
            component: () => import('./views/SignupSteps/ThemeSelection.vue'),
            path: 'theme',
            name: 'theme'
          },
          {
            path: '/billing',
            name: 'billing',
            meta: {
              title: 'Billing',
              isSignupStep: true,
              step: 4
            },
            component: () => import('./views/SignupSteps/Billing.vue')
          },
          {
            path: '/billing/checkout',
            name: 'billing-checkout',
            meta: {
              title: 'Billing',
              isSignupStep: true
            },
            component: () => import('./views/SignupSteps/BillingCheckout.vue')
          }
        ]
      },
      {
        path: '/login',
        name: 'login',
        meta: {
          title: 'Login'
        },
        component: () => import('./views/Login.vue')
      },
      {
        path: '/appsumo/error',
        name: 'appsumo-error',
        meta: {
          title: 'Error'
        },
        component: () => import('./views/AppSumoError.vue')
      },
      {
        path: '/:pathMatch(.*)',
        redirect: '/'
      }
    ]
  })
}

export { router }
