export const SocialAuthOptions = {
  tokenPath: 'accessToken',
  token: 'social_token',
  providers: {
    facebook: {
      clientId: import.meta.env.VITE_FACEBOOK_CLIENT_ID,
      redirectUri: import.meta.env.VITE_SIGNUP_URL,
      responseType: 'code',
      authorizationEndpoint: import.meta.env.VITE_FACEBOOK_AUTH_ENDPOINT,
      requiredUrlParams: ['display', 'scope'],
      scope: ['email'],
      display: 'popup',
      oauthType: '2.0'
    },
    linkedin: {
      name: 'linkedin',
      clientId: import.meta.env.VITE_LINKEDIN_CLIENT_ID,
      authorizationEndpoint: import.meta.env.VITE_LINKEDIN_AUTH_ENDPOINT,
      redirectUri: import.meta.env.VITE_SIGNUP_URL,
      requiredUrlParams: ['state', 'scope'],
      scope: ['r_emailaddress r_liteprofile'],
      scopeDelimiter: ' ',
      state: 'STATE',
      oauthType: '2.0',
      popupOptions: { width: 527, height: 582 }
    },
    google: {
      name: 'google',
      clientId: import.meta.env.VITE_GOOGLE_CLIENT_ID,
      authorizationEndpoint: import.meta.env.VITE_GOOGLE_AUTH_ENDPOINT,
      redirectUri: import.meta.env.VITE_SIGNUP_URL,
      requiredUrlParams: ['scope'],
      optionalUrlParams: ['display'],
      scope: ['profile', 'email'],
      scopePrefix: 'openid',
      scopeDelimiter: ' ',
      display: 'popup',
      oauthType: '2.0',
      popupOptions: { width: 452, height: 633 }
    }
  }
}
